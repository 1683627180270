.LLogo {
    display: flex;
    justify-content: center;
    padding-top: 1rem;
}

.Logincard {

    margin-top: 3rem;
    margin-bottom: 40px;
    margin-left: 30px;
    border-radius: 10px !important;
    background: var(--background-paper-elevation-0, #FFF);
    box-shadow: -2px 2px 14px 0px rgba(1, 0, 0, 0.15) !important;
    /* width: 100% !important; */

}

.L_cardcontent {
    display: flex;
    flex-direction: column;

}

.Login{
    color: #CF796C;
    padding-top: 10px;
    padding-bottom: 30px;
    font-size: 40px !important;
    text-align: center;
}   