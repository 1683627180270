.border-0::before{
    border: none!important;
}
/* .search_input{
    width: 35%;
} */
.css-v475nq-MuiButtonBase-root-MuiListItemButton-root.active{
  background-color: #CF796C;
  color: #fff;
}
.css-v475nq-MuiButtonBase-root-MuiListItemButton-root{
  color: #969696;
  font-size: 15px;
  font-weight: 500;
}
  .nameFontSize {
    font-size: 19px;
    font-weight: 500;
  }
  
  .userNameFontSize {
    font-size: 14px;
    color: #B4B4B4;
  }
  .feedpage-btn{
    color: #FFFFFF;
    background-color: #CF796C;
    padding: 5px 32px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    margin-left: 10px;
    /* margin-right: 10px; */
    border: none;
  }
  .btn-event{
    color: #FFFFFF;
    background-color: #CF796C;
    padding: 10px 35px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    margin-left: 10px;
    margin-right: 10px;
    border: none;
    border-radius: 6px;
  }
  .btn-event1{
    color: #FFFFFF;
    background-color: #0066FF;
    padding: 10px 25px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    margin-left: 10px;
    margin-right: 10px;
    border: none;
    border-radius: 6px;
  }
  .delete-btn{
    color: #FFFFFF;
    background-color: #F20202;
    padding: 4px 25px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    margin-left: 10px;
    margin-right: 10px;
    border: none;
    border-radius: 6px;
  }
  
  .delete-btn:hover{
    background-color: #F20202;
  }
  .edit-btn{
    color: #F20202;
    padding: 5px 48px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    margin-left: 10px;
    margin-right: 10px;
    border-color: #F20202;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .notification{
    background-color:  #C08C5D;
    border:  #C08C5D;
    border-radius: 5px;
    padding: 3px;
    margin-left: 5px;
  }
  .search-btn{
    /* margin-right: 20px; */
    background-color: #fff;
    border: none;
  }
  .feedpage-btn:hover{
   background-color: #CF796C;
  }
  /* .css-12vebo6-MuiButtonBase-root-MuiButton-root:hover{
    background-color: #CF796C !important;
  } */
  .css-e9crry-MuiInputBase-input-MuiOutlinedInput-input{
    padding: 13.5px 14px !important;
  }
  .adduser-btn{
    color: #FFFFFF;
    background-color: #0066FF;
    padding: 6px 38px;
    font-size: 16px;
    font-weight: 500;
    margin-right: 20px;
  }
  .adduser-btn:hover{
    background-color: #0066FF;
  }
  .css-h3bten-MuiButtonBase-root-MuiIconButton-root{
    display: none;
  }
  .feed-page-button-container{
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .add_event{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 20px;
  }
  .notification-icon{
    color: #fff;
    background-color: #C08C5D;
  }
  .event-cancel{
    padding: 6px 20px;
    border-color: #CF796C;
    /* border: #CF796C; */
    border-radius: 5px;
    font-size: 16px;
    font-weight: 500;
    margin-top: 10px;

  }
  .event-update{
    padding: 8px 23px;
    margin-left: 15px;
    border-color: #CF796C;
    border-radius: 5px;
    border: none;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    margin-top: 10px;
  }
.event-edit-head{
font-size: 19px;
margin-bottom: 12px;
}