.cursor-pointer {
    cursor: pointer;
  }
  

.add_event{
    display: flex;
    color: #CF796C;
    margin-left: auto;
}

.image-flex{
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-top: 30px; 
   
}
.cardstyle{
    margin-bottom: 30px;
    padding-bottom: 30px;
    margin: 2px;
}
.img-width {
    width: 380px;
    height: 388px;
    background-size: cover;
    background-position: center;
    cursor: pointer; 
    border: 12px solid #fff;
    margin: 2px;
   
  }
  

.dialog_data{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 60px;
    
}
.edit_dialog_data{
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #ccc; 
    
}

.closeBg1{
    border-radius: 5px var(--none, 0px) 30px var(--none, 0px); 
    background: rgba(0, 0, 0, 0.15);
    transform: rotate(90deg); 
    padding: 10px;
}
.upload_icon{
    background: #C08C5D;
    border-radius: 50%;  
    padding: 18px;   
   
}
.submit_cancel_buttons{
    margin-bottom: 40px;
  
}

.submit_button{
    background: #CF796C; 
    box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.20);

}
.cancel_button{
    border-radius: 45px;
    background: var(--background-paper-elevation-0, #FFF);
    box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.20);
}

.max_img_size{
    color: #485867;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
}

.selected_image_container{
    margin-top: 10px; 
}

.selected_image {
    width: 23rem; 
    height: 18rem; 
    border: 1px solid #ccc; 
  }

.Description{
    margin-top: 20px; 
    margin-bottom: 15px;
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
}