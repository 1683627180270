
.cardstyle{
  margin-bottom: 8px;
}
  
 .number_of_reports{
  text-align: right;
 
 }
 .card_CardContent{
  display: flex;
  justify-content: space-between;
 }
.delete_reports{
  text-align: right;
}
